import bus from '$bus';
import _ from 'lodash';

import {
  startOfMonth,
  endOfMonth,
  addMonths,
  differenceInMonths,
  format,
} from 'date-fns';

export const isDarkMode = () => {
  if (
    window.localStorage.theme === 'dark'
    || (
        !('theme' in window.localStorage)
        && window.matchMedia('(prefers-color-scheme: dark)').matches
    )
  ) return true;
  return false;
}

export const toggleDarkMode = (appearance: string) => {
  if (appearance === 'dark') localStorage.theme = 'dark';
  if (appearance === 'light') localStorage.theme = 'light';
  if (appearance === 'system-preference') localStorage.removeItem('theme');
  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  const result = isDarkMode();
  bus.emit('appearance-changed', result);
  if (result) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

export const exportDataToCSV = (data: any[], name: any) => {
  const output = data.map((i: any) => i.map((j: any) => `"${j}"`).toString()).join('\n');
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(output)}`);
  link.setAttribute('download', `${name}.csv`);
  link.click();
};

export const addGoogleChartsLoaderIfNotExist = () => {
  const gstaticSrc = 'https://www.gstatic.com/charts/loader.js';
  if (!document.querySelector(`script[src="${gstaticSrc}"]`)) {
    const script = document.createElement('script');
    script.src = gstaticSrc;
    script.type = 'text/javascript';
    document.querySelector('head').appendChild(script);
  }
};

export const ordinalSuffixOf = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) return `${i}st`;
  if (j == 2 && k != 12) return `${i}nd`;
  if (j == 3 && k != 13) return `${i}rd`;
  return `${i}th`;
};

export const distance = (meters: number, store: any, precision = 1) => {
  if (!store.state.user) return '';
  let value: any = meters / 1000;
  let unit = 'km';
  if (store.state.useImperial) {
    value = meters / 1609.344;
    unit = 'mi';
  }
  // show precision if the value was lower than 1000
  value = value.toFixed(value < 1000 ? precision : 0);
  return `${parseFloat(value).toLocaleString('en')}\xa0${unit}`;
};

export const speed = (speed: number, store: any) => {
  if (!store.state.user || !_.isNumber(speed)) return '-';
  if (store.state.useImperial) {
    return `${Math.floor(speed / 1.609)}\xa0mph`;
  }
  return `${Math.floor(speed)}\xa0km/hr`;
};

export const monthRangesBetweenTwoDates = (from: Date, to: Date) => {
  const diff = differenceInMonths(to, from);
  const r = [];
  for (let i = 0; i <= diff; i += 1) {
    r.push({
      from: startOfMonth(addMonths(from, i)),
      to: endOfMonth(addMonths(from, i)),
    });
  }
  return r;
};

export const flatten = (obj: any, key: string) => {
  let output = {};
  for (const i in obj) {
    if (!Reflect.ownKeys(obj).length) continue;
    output = {
      ...output,
      ...((obj[i] && typeof obj[i] === 'object' ? flatten(obj[i], i) : {
        [key ? `${key}_${i}` : i]: obj[i],
      })),
    };
  }
  return output;
};

export const secondsToDuration = (seconds: number) => {
  let result = '';
  const hours = Math.floor(seconds / 3600);
  if (hours) result = `${hours} hr`;
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  if (hours) result += ' ';
  result += `${minutes} min`;
  if (!hours && !minutes) {
    const s = Math.floor(seconds);
    if (s) {
      result = `${s} sec`;
    } else {
      result = '0';
    }
  }
  return result;
};

const dateFromTimestamp = (timestamp: number) => {
  if (!timestamp) return '';
  return format(new Date(timestamp * 1000), 'yyyy-MM-dd HH:mm:ss').replace(' ', '\xa0');
};

export default {
  isDarkMode,
  toggleDarkMode,
  exportDataToCSV,
  addGoogleChartsLoaderIfNotExist,
  ordinalSuffixOf,
  distance,
  speed,
  monthRangesBetweenTwoDates,
  flatten,
  secondsToDuration,
  dateFromTimestamp,
};
