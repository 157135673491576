import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCA6wHstUmN6NEa_M1j6vy5MPaSK9HN8GE",
    authDomain: "portal-16812.firebaseapp.com",
    projectId: "portal-16812",
    storageBucket: "portal-16812.appspot.com",
    messagingSenderId: "262660425602",
    appId: "1:262660425602:web:d154813fd885328dd9f19b",
    measurementId: "G-TL14R6E5BP"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
const storage = getStorage(app);

export default {
    app,
    auth,
    db,
    storage,
};
