export default [
  {
    name: 'Users',
    path: '/users',
    component: () => import('../views/users/page.vue'),
    props: {
      parent: 'team',
      icon: 'group',
    },
  },
  {
    name: 'Connections',
    path: '/connections',
    component: () => import('../views/connections/page.vue'),
    props: {
      parent: 'team',
      icon: 'link',
    },
  },
  {
    name: 'Integration',
    path: '/integration',
    component: () => import('../views/integration/page.vue'),
  },
  // We're showing it as Fleet Group cause insurer role doesn't have access group
  {
    name: 'Fleet Groups',
    path: '/fleet-groups',
    component: () => import('../views/fleet-groups/page.vue'),
    props: {
      parent: 'team',
      icon: 'groups',
      excludeRoles: ['fleet-manager'],
    },
  },
  {
    name: 'Vehicles',
    path: '/vehicles',
    component: () => import('../views/vehicles/page.vue'),
    props: {
      parent: 'view',
      icon: 'directions_car',
    },
  },
  {
    name: 'Map',
    path: '/map',
    component: () => import('../views/map/page.vue'),
    props: {
      parent: 'view',
      icon: 'map',
    },
  },
  {
    name: 'Events',
    path: '/events',
    component: () => import('../views/events/page.vue'),
    props: {
      parent: 'view',
      icon: 'minor_crash',
      hide_children: true,
    },
    children: [
      {
        name: 'Event',
        path: ':rec_id',
        component: () => import('../views/events/event/page.vue'),
      },
    ],
  },
  {
    name: 'Drivers',
    path: '/drivers',
    component: () => import('../views/drivers/page.vue'),
    props: {
      parent: 'team',
      icon: 'driver',
      customIcon: true,
    },
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import('../views/profile/page.vue'),
    props: {
      manual: true,
    },
    children: [
      {
        name: 'Preferences',
        path: 'preferences',
        component: () => import('../views/profile/preferences/page.vue'),
      },
      {
        name: 'User Details',
        path: 'user-details',
        component: () => import('../views/profile/user-details/page.vue'),
      },
      {
        name: 'Change Password',
        path: 'change-password',
        component: () => import('../views/profile/change-password/page.vue'),
      },
    ],
  },
  {
    name: 'Policies',
    path: '/policies',
    component: () => import('../views/policies/page.vue'),
    props: {
      parent: 'view',
      icon: 'local_police',
    },
  },
  {
    name: 'Analytics',
    path: '/analytics',
    component: () => import('../views/analytics/page.vue'),
    props: {
      parent: 'analytics',
    },
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        props: {
          icon: 'space_dashboard',
        },
        component: () => import('../views/analytics/dashboard/page.vue'),
      },
      {
        name: 'Fleet Risk',
        path: 'fleet-risk',
        props: {
          icon: 'pie_chart',
        },
        component: () => import('../views/analytics/fleet-risk/page.vue'),
      },
      {
        name: 'Driver Risk',
        path: 'driver-risk',
        props: {
          icon: 'driver-risk',
          customIcon: true,
        },
        component: () => import('../views/analytics/driver-risk/page.vue'),
      },
      {
        name: 'Incident Report',
        path: 'incident-report',
        props: {
          icon: 'warning',
        },
        component: () => import('../views/analytics/incident-report/page.vue'),
      },
      {
        name: 'Accident Report',
        path: 'accident-report',
        props: {
          icon: 'accident',
          customIcon: true,
        },
        component: () => import('../views/analytics/accident-report/page.vue'),
      },
    ],
  },
  {
    name: 'Coaching',
    path: '/coaching',
    component: () => import('../views/coaching/page.vue'),
    props: {
      parent: 'coaching',
    },
    children: [
      {
        name: 'Coaching Report',
        path: 'coaching-report',
        props: {
          icon: 'feedback',
        },
        component: () => import('../views/coaching/coaching-report/page.vue'),
      },
      {
        name: 'Sharing',
        path: 'sharing',
        props: {
          icon: 'share',
        },
        component: () => import('../views/coaching/sharing/page.vue'),
      },
      {
        name: 'Messages',
        path: 'messages',
        props: {
          icon: 'support_agent',
        },
        component: () => import('../views/coaching/messages/page.vue'),
      },
    ],
  },
  {
    name: 'Data Engine',
    path: '/data-engine',
    component: () => import('../views/data-engine/page.vue'),
    props: {
      parent: 'data-engine',
    },
    children: [
      {
        name: 'Validation Report',
        path: 'validation-report',
        props: {
          icon: 'checklist',
        },
        component: () => import('../views/data-engine/validation-report/page.vue'),
      },
      {
        name: 'TSP Performance',
        path: 'tsp-performance',
        props: {
          icon: 'query_stats',
        },
        component: () => import('../views/data-engine/tsp-performance/page.vue'),
      },
    ],
  },
]