import { createRouter, createWebHistory } from 'vue-router';

import AuthRoutes from './auth.ts';
import RegularRoutes from './regular.ts';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...AuthRoutes,
        ...RegularRoutes,
        {
            path: '/:pathMatch(.*)*',
            component: () => import('../views/404/page.vue'),
        },
    ],
});

export default router;