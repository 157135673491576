/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, getDocs, query, where, and } from "firebase/firestore";
import { db } from "./_globals";

export const getIncidents = async () => {
  const q = query(
    collection(
      db,
      'incidents',
    ),
  );
  const snapshot = await getDocs(q);
    const res: any[] = [];
    snapshot.forEach((i) => res.push({
        id: i.id,
        ...i.data(),
    }));
    return res;
};

export default {
  getIncidents,
};