/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, getDocs, limit, query, setDoc, getDoc, and, doc, where } from "firebase/firestore";
import { db } from "./_globals";

export const getAllGroups = async () => {
  const q = query(
    collection(
      db,
      'groups',
    ),
    where('deleted_at', '==', null),
  );
  const snapshot = await getDocs(q);
  const res: any[] = [];
  snapshot.forEach(async (i) => res.push({
      id: i.id,
      ...i.data(),
  }));
  return res;
};

interface CreateGroupContext {
  name: string;
  parent_id: string;
  role: string;
};
export const createGroup = async (context: CreateGroupContext) => {
  const ref = doc(collection(db, 'groups'));
  const content = {
    name: context.name,
    role: context.role,
    parent_id: context.parent_id ?? null,
    deleted_at: null,
  };
  await setDoc(ref, content);
  return ref;
};

interface GetGroupByIdContext {
  group_id: string;
};
export const getGroupById = async (context: GetGroupByIdContext) => {
  const ref = doc(db, 'groups', context.group_id);
  const snapshot = await getDoc(ref);
  if (!snapshot.exists()) return;
  const group = snapshot.data();
  return {
      id: snapshot.id,
      ...group,
  };
};

interface UpdateGroupContext {
  group_id: string;
  name?: string;
};
export const updateGroup = async (context: UpdateGroupContext) => {
  const group: any = await getGroupById(context);
  if (!group || group.deleted_at) return;
  const ref = doc(db, 'groups', group?.id);
  delete group.id;
  const content = {
    ...group,
    name: context?.name ?? group.name,
  };
  await setDoc(ref, content);
  return ref;
};

export default {
  getAllGroups,
  createGroup,
  getGroupById,
  updateGroup,
};