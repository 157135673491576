/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, getDocs, limit, query, setDoc, and, doc, where } from "firebase/firestore";
import { db } from "./_globals";

interface GetUserMetadataContext {
  user_id: string;
};
export const getUserMetadata = async (context: GetUserMetadataContext) => {
  const q = query(
    collection(
      db,
      'users_metadata',
    ),
    where('user_id', '==', context?.user_id),
    limit(1),
  );
  const snapshot = await getDocs(q);
  const r = snapshot.docs[0]
    && {
    id: snapshot.docs[0].id,
    ...snapshot.docs[0]?.data(),
  };
  return r;
};

interface GetUserMetadataByEmailContext {
  email: string;
};
export const getUserMetadataByEmail = async (context: GetUserMetadataByEmailContext) => {
  const q = query(
    collection(
      db,
      'users_metadata',
    ),
    where('email', '==', context?.email),
    limit(1),
  );
  const snapshot = await getDocs(q);
  const r = snapshot.docs[0]
    && {
    id: snapshot.docs[0].id,
    ...snapshot.docs[0]?.data(),
  };
  return r;
};

interface GetAllUsersMetadataContext {
  group_ids: Array<string>;
};
export const getAllUsersMetadata = async (context: GetAllUsersMetadataContext) => {
  if (!context.group_ids?.length) return [];
  const q = query(
    collection(
      db,
      'users_metadata',
    ),
    and(
      where('deleted_at', '==', null),
      where('group_id', 'in', context.group_ids),
    ),
  );
  const snapshot = await getDocs(q);
    const res: any[] = [];
    snapshot.forEach((i) => res.push({
        id: i.id,
        ...i.data(),
    }));
    return res;
};

interface DeleteUserContext {
  user_id?: string;
  email?: string;
};
export const deleteUser = async (context: DeleteUserContext) => {
  if (!context?.user_id && !context?.email) return;
  let userMetadata;
  if (context?.user_id) {
    userMetadata = await getUserMetadata(context);
    if (userMetadata?.user_id !== context.user_id) return;
  } else if (context?.email) {
    userMetadata = await getUserMetadataByEmail(context);
    if (userMetadata?.email !== context.email) return;
  }
  const ref = doc(db, 'users_metadata', userMetadata.id);
  const content = {
    ...userMetadata,
    deleted_at: new Date(),
  };
  await setDoc(ref, content);
  return ref;
};

interface CreateUserContext {
  email: string;
  role: string;
  group_id: string;
};
export const createUser = async (context: CreateUserContext) => {
  const userMetadata = await getUserMetadataByEmail({
    email: context.email,
  });
  if (userMetadata) return;
  const ref = doc(collection(db, 'users_metadata'));
  await setDoc(ref, {
    email: context.email,
    role: context.role,
    group_id: context.group_id,
    signed_up_at: null,
    deleted_at: null,
  });
  return ref;
};

interface UpdateUserContext {
  user_id: string;
  phone_number: string;
  role: string;
  group_id: string;
  first_name: string;
  last_name: string;
  company: string;
};
export const updateUser = async (context: UpdateUserContext) => {
  const userMetadata: any = await getUserMetadata({
    user_id: context.user_id,
  });
  if (!userMetadata) return;
  const ref = doc(db, 'users_metadata', userMetadata.id);
  const payload = userMetadata;
  delete payload.id;
  if (context.phone_number) payload.phone_number = context.phone_number;
  if (context.role) payload.role = context.role;
  if (context.group_id) payload.group_id = context.group_id;
  if (context.first_name) payload.first_name = context.first_name;
  if (context.last_name) payload.last_name = context.last_name;
  if (context.company) payload.company = context.company;
  await setDoc(ref, payload);
  return ref;
};

interface completeUserSignupContext {
  email: string;
  user_id: string;
};
export const completeUserSignup = async (context: completeUserSignupContext) => {
  const userMetadata: any = await getUserMetadataByEmail({
    email: context.email,
  });
  if (!userMetadata) return;
  const ref = doc(db, 'users_metadata', userMetadata.id);
  let payload = userMetadata;
  delete payload.id;
  if (!payload.user_id && context.user_id) {
    await setDoc(ref, {
      ...payload,
      user_id: context.user_id,
      signed_up_at: new Date(),
    });
  }
  return ref;
};

export default {
  getUserMetadata,
  getUserMetadataByEmail,
  getAllUsersMetadata,
  deleteUser,
  createUser,
  updateUser,
  completeUserSignup,
};