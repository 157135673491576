import App from './App.vue';
import router from './router';
import store from './store';

import ForesightUIComponents from 'foresight-ui-components';
import {createApp} from 'vue/dist/vue.esm-bundler';

import 'foresight-ui-components/dist/style.css';
import './style.css';

window.app = createApp(App)
  .use(store)
  .use(router)
  .use(ForesightUIComponents)
  .mount('#app');
