/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, getDocs, limit, query, setDoc, getDoc, and, doc, where } from "firebase/firestore";
import { db } from "./_globals";

interface GetFleetByIdContext {
  fleet_id: string;
};
export const getFleetById = async (context: GetFleetByIdContext) => {
  const ref = doc(db, 'fleets', context.fleet_id);
  const snapshot = await getDoc(ref);
  if (!snapshot.exists()) return;
  const fleet = snapshot.data();
  return {
      id: snapshot.id,
      ...fleet,
  };
};

interface GetFleetByNameContext {
  group_id: string;
  name: string;
};
export const getFleetByName = async (context: GetFleetByNameContext) => {
  const q = query(
    collection(
      db,
      'fleets',
    ),
    and(
      where('group_id', '==', context?.group_id),
      where('name', '==', context?.name),
    ),
    limit(1),
  );
  const snapshot = await getDocs(q);
  const r = snapshot.docs[0]
    && {
    id: snapshot.docs[0].id,
    ...snapshot.docs[0]?.data(),
  };
  return r;
};

export const getAllFleets = async () => {
  const q = query(
    collection(
      db,
      'fleets',
    ),
    where('deleted_at', '==', null),
  );
  const snapshot = await getDocs(q);
    const res: any[] = [];
    snapshot.forEach((i) => res.push({
        id: i.id,
        ...i.data(),
    }));
    return res;
};

interface UpdateFleetContext {
  fleet_id: string;
  name?: string;
};
export const updateFleet = async (context: UpdateFleetContext) => {
  const fleet: any = await getFleetById(context);
  if (!fleet || fleet.deleted_at) return;
  const ref = doc(db, 'fleets', fleet?.id);
  delete fleet.id;
  const content = {
    ...fleet,
    name: context?.name ?? fleet.name,
  };
  await setDoc(ref, content);
  return ref;
};

interface DeleteFleetContext {
  fleet_id: string;
};
export const deleteFleet = async (context: DeleteFleetContext) => {
  const fleet: any = await getFleetById(context);
  if (!fleet || fleet.deleted_at) return;
  const ref = doc(db, 'fleets', fleet.id);
  delete fleet.id;
  const content = {
    ...fleet,
    deleted_at: new Date(),
  };
  await setDoc(ref, content);
  return ref;
};

interface CreateFleetContext {
  name: string;
  tsp: string;
  api_key: string;
  group_id: string;
};
export const createFleet = async (context: CreateFleetContext) => {
  const fleet = await getFleetByName({
    group_id: context.group_id,
    name: context.name,
  });
  if (fleet) return;
  const ref = doc(collection(db, 'fleets'));
  await setDoc(ref, {
    group_id: context.group_id,
    name: context.name,
    tsp: context.tsp,
    api_key: context.api_key,
    api_key_validated: false,
    deleted_at: null,
    created_at: new Date(),
  });
  return ref;
};

export default {
  getFleetById,
  getFleetByName,
  getAllFleets,
  deleteFleet,
  updateFleet,
  createFleet,
};