import { groups, fleets, vehicles, drivers, incidents } from '$firebase';
import { createStore } from 'vuex';
import _ from 'lodash';

const getAvailableGroups = (groups: any, parentId: string) => {
    const origin = groups
        .filter((i: any) => i.parent_id === parentId)
        .map((i: any) => i.id);
    const result = [
        parentId,
        ...origin,
        ...origin.reduce((a: any, i: any) => {
            a.push(...getAvailableGroups(groups, i));
            return a;
        }, []),
    ];
    return [...new Set(result)];
};

const state = {
    loaded: false,
    authenticated: null,
    user: null,
    groups: [],
    fleets: [],
    vehicles: [],
    drivers: [],
    incidents: [],
    useImperial: false,
};
const getters = {
    unit(state: any) {
        return state.useImperial ? 'mi' : 'km';
    },
    isFleetManagerRole(state: any) {
        return state.user?.role === 'fleet-manager';
    },
    isInsurerRole(state: any) {
        return state.user?.role === 'insurer';
    },
    adminGroups() {
        return state.groups.filter((i: any) => !i.parent_id);
    },
    insurerGroups() {
        let result: any = [];
        state.groups.forEach((i: any) => {
            result = [...result, ...i.children];
        });
        return result;
    },
    allGroupsById(state: any) {
        return Object.fromEntries(state.groups.map((i: any) => [i.id, i]));
    },
    availableGroups(state: any) {
        if (!state.user || !state.groups) return [];
        return getAvailableGroups(state.groups, state.user?.group_id);
    },
    availableFleets(state: any, getters: any) {
        if (!state.user || !state.groups) return [];
        return state.fleets.filter((i: any) => getters.availableGroups.includes(i.group_id));
    },
    availableFleetIds(_state: any, getters: any) {
        return getters.availableFleets.map((i: any) => i.id);
    },
    availableFleetsByConnectionId(_state: any, getters: any) {
        return Object.fromEntries(getters.availableFleets.map((i: any) => [i.connection_id, i]));
    },
    availableFleetsById(_state: any, getters: any) {
        return Object.fromEntries(getters.availableFleets.map((i: any) => [i.id, i]));
    },
    availableVehicles(state: any, getters: any) {
        return state.vehicles.filter((i: any) => getters.availableFleetIds.includes(i.fleet_id));
    },
    allVehiclesById(_state: any) {
        return Object.fromEntries(_state.vehicles.map((i: any) => [i.id, i]));
    },
    availableVehiclesById(_state: any, getters: any) {
        return Object.fromEntries(getters.availableVehicles.map((i: any) => [i.id, i]));
    },
    availableDrivers(state: any, getters: any) {
        return state.drivers.filter((i: any) => getters.availableFleetIds.includes(i.fleet_id));
    },
    allDriversById(_state: any) {
        return Object.fromEntries(_state.drivers.map((i: any) => [i.id, i]));
    },
    availableDriversById(_state: any, getters: any) {
        return Object.fromEntries(getters.availableDrivers.map((i: any) => [i.id, i]));
    },
    allIncidentsByName(state: any) {
        return Object.fromEntries(state.incidents.map((i: any) => [i.name, i]));
    },
    incidents(state: any) {
        return state.incidents.filter((i: any) => !i.extended);
    },
    incidentsByName(_state: any, getters: any) {
        return Object.fromEntries(getters.incidents.map((i: any) => [i.name, i]));
    },
    extendedIncidents(state: any) {
        return state.incidents.filter((i: any) => i.extended);
    },
    extendedIncidentsByName(_state: any, getters: any) {
        return Object.fromEntries(getters.extendedIncidents.map((i: any) => [i.name, i]));
    },
};
const mutations = {
    setUser(state: any, v: any) {
        state.user = v;
    },
    authenticate(state: any, v: any) {
        state.authenticated = true;
        this.commit('setUser', v);
        state.user = v;
    },
    deauthenticate(state: any) {
        state.authenticated = false;
        state.user = null;
    },
    setGroups(state: any, v: any) {
        state.groups = v;
    },
    setFleets(state: any, v: any) {
        state.fleets = v;
    },
    setVehicles(state: any, v: any) {
        state.vehicles = v;
    },
    setDrivers(state: any, v: any) {
        state.drivers = v;
    },
    setIncidents(state: any, v: any) {
        state.incidents = v.filter((i: any) => !i.hidden);
    },
};
const actions = {
    async updateGroups(context: any) {
        const v = await groups.getAllGroups();
        context.commit('setGroups', v);
    },
    async updateFleets(context: any) {
        const v = await fleets.getAllFleets();
        context.commit('setFleets', v);
    },
    async updateVehicles(context: any) {
        const v = await vehicles.getAllVehicles();
        context.commit('setVehicles', v);
    },
    async updateDrivers(context: any) {
        const v = await drivers.getAllDrivers();
        context.commit('setDrivers', v);
    },
    async updateIncidents(context: any) {
        const v = await incidents.getIncidents()
        context.commit('setIncidents', v);
    },
};

export default createStore({ state, getters, mutations, actions });