/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./_globals";

export const getAllVehicles = async () => {
  const q = query(
    collection(
      db,
      'vehicles',
    ),
    where('deleted_at', '==', null),
  );
  const snapshot = await getDocs(q);
    const res: any[] = [];
    snapshot.forEach((i) => res.push({
        id: i.id,
        ...i.data(),
    }));
    return res;
};

export default {
  getAllVehicles,
};