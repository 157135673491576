export default [
    {
        name: 'Login',
        path: '/login',
        component: () => import('../views/login/page.vue'),
    },
    {
        name: 'Signup',
        path: '/signup',
        component: () => import('../views/signup/page.vue'),
    },
]