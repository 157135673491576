<template>
  <Header
    v-if="
      !$route.path.match(publicPageRegex)
      && ($store.state.authenticated && !$route.path.match(restrictedPageRegex))"
  />
  <router-view :key="$route.path" />
</template>

<script>
import { _globals, users_metadata } from '$firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import Header from './header.vue';
import { utils } from '$lib';

const publicPageRegex = new RegExp('\/(login|signup)', 'i');
const restrictedPageRegex = new RegExp('\/(integration)', 'i');

export default {
  name: 'AppView',
  components: {
    Header,
  },
  data() {
    return {
      publicPageRegex,
      restrictedPageRegex,
    };
  },
  watch: {
    '$store.state.loaded': function watcher() {
      if (this.$store.state.loaded) {
        if (!this.$store.state.authenticated) {
          if (
            !this.$route.path.match(publicPageRegex)
            && !this.$route.path.match(restrictedPageRegex)
          )return this.$router.push('/login');
        } else {
          if (this.$route.path.match(publicPageRegex)) {
            this.$router.push('/analytics/fleet-risk');
          }
        }
      }
    },
  },
  mounted() {
    onAuthStateChanged(_globals.auth, async (v) => {
      if (v) {
        let userMetadata = await users_metadata.getUserMetadataByEmail({
          email: v.email,
        });
        await Promise.all([
          this.$store.commit('authenticate', {
            ...userMetadata,
            signed_up_at: userMetadata.signed_up_at
              ? new Date(userMetadata.signed_up_at?.seconds * 1000) : null,
          }),
          this.$store.dispatch('updateGroups', userMetadata.group_id),
          this.$store.dispatch('updateFleets'),
          this.$store.dispatch('updateVehicles'),
          this.$store.dispatch('updateDrivers'),
          this.$store.dispatch('updateIncidents'),
        ]);
      }
      this.$store.state.loaded = true;
    });
  },
  async created() {
    utils.toggleDarkMode(localStorage.theme ?? 'system-preference');
  },
};
</script>

<style>
body {
  @apply bg-slate-100 dark:!bg-slate-800;
}
</style>